/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components 
 


import { Helmet } from 'react-helmet-async';  
import { Card } from "@mui/material"; 

import Information from "./sections/Information";
import Team from "./sections/Team";
import Featuring from "./sections/Featuring";
import Newsletter from "./sections/Newsletter"; 

// Routes
// import routes from "routes";
// import footerRoutes from "footer.routes";
 

function AboutUs() {
  return (
    <> 
      <Helmet>
        <title> About Us | PlanetryVilage LLC </title>
      </Helmet>

      <Card
        sx={{
          // p: 2,
          p: 0,
          mx: { xs: 2, lg: 3 },
          // mt: 3,
          mt: 1,
          mb: 4,
          // boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information /> 
        <Team /> 
        <Featuring />
        <Newsletter /> 
      </Card>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default AboutUs;
