/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components 

// Material Kit 2 React components 
import {Box, Grid, Container, Typography} from "@mui/material";

// Material Kit 2 React examples
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "../../../assets/images/team-5.jpg";
import team2 from "../../../assets/images/bruce-mars.jpg";
import team3 from "../../../assets/images/ivana-squares.jpg";
import team4 from "../../../assets/images/ivana-square.jpg";

function Team() {
  return (
    <Box
      component="section"
      variant="gradient" 

      sx={{ position:"relative", px:{ xs: 2, lg: 0,  }, py:6, mx:-2,
      background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)' }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <Typography variant="h3" color="white">
              The Executive Team
            </Typography>
            <Typography variant="body2" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
              at. That&apos;s my skill.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Emma Roberts"
                position={{ color: "info", label: "Senior UI Designer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="William Pearce"
                position={{ color: "info", label: "Chief Data Scientist" }}
                description="Working on cutting-edge systems has increase my passion for Machine Learning. Exactly..."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Ivana Flow"
                position={{ color: "info", label: "Expert Solutions Architect" }}
                description="I have dedicated all my life to make the IT industry move forward. I am so passionate."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="I dream of programming. I only think about programming. I have a great passion for IT."
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Team;
