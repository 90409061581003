/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components 

import {Box, Grid, Container, } from "@mui/material"; 

// Material Kit 2 React examples
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <Box component="section" py={2}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <Box  sx={{mb:5}} >
                  <DefaultInfoCard
                    icon="public"
                    title="Fully integrated"
                    description="We have integrated chatbots into apps to make them more efficient"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{mb:5}}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Multistreaming platform"
                    description="Stream simultaneously on Facebook, Youtube, Tiktok, and ..."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{mb:{ xs: 5, md: 0 }, }} >
                  <DefaultInfoCard
                    icon="apps"
                    title="Social media platform"
                    description="We have the expertise to create apps that connect people globally"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{mb:{ xs: 5, md: 0 }, }} >
                  <DefaultInfoCard
                    icon="3p"
                    title="Improved platform"
                    description="We have enhanced so many existing platforms all around the world"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Information;
