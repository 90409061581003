/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components 


import {Box, Grid, Card, Typography} from "@mui/material";

// Material Kit 2 React components
/*
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography"; */

function HorizontalTeamCard({ image, name, position, description }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <Box   sx={{width:"100%", pt:2, pb:1, px:2 }} >
            <Box
              component="img"
              src={image}
              alt={name}
              width="100%"
              // borderRadius="md"
              // shadow="lg"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <Box sx={{pt:{ xs: 1, lg: 2.5 }, pb:2.5, pr:4, pl:{ xs: 4, lg: 1 }, lineHeight:1 }}  >
            <Typography variant="h5">{name}</Typography>
            <Typography variant="h6" color={position.color} mb={1}>
              {position.label}
            </Typography>
            <Typography variant="body2" color="text">
              {description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
