import {useState } from 'react';
import { Helmet } from 'react-helmet-async';  
import axios from 'axios';  
import swal from "sweetalert";   

// Material Kit 2 React components 
import { Grid, Box, TextField, Button, Typography } from "@mui/material";  
  
 
import {domainName} from '../../utils/domain';

function ContactUs() {

  const [error, setError] = useState('');

  const [fullname, setFullname] = useState('');  
  const [email, setEmail] = useState(''); 
  const [message, setMessage] = useState();  


  const onChangeFullname = (e) => {
    setFullname(e.target.value);  
  } 

  const onChangeEmail = (e) => {
    setEmail(e.target.value);  
  } 

  const onChangeMessage = (e) => {
    setMessage(e.target.value);  
  } 



  /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/business/contact`;

  const upload = async () => {   
    const resp = await sendFileData(fullname, email, message, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message;  

    if(await jsres === 'success') {
      swal("Success", "We have received your mail!", "success"); 

      setError('');
      setFullname('');
      setEmail('');
      setMessage('');
    } else { 
      swal("Oops", "An error occured!", "error");
    } 
  } 
  

  const sendFileData = (fullname, email, message, url_) => {
     
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {  
        hostname: window.location.hostname, 
        fullname, 
        email, 
        message,   
      }  
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 


  const sendMessage = async () => {
    if(!fullname) {
      setError('Enter your fullname');
      return;
    }

    if(!email) { 
      setError('Enter your email');
      return;
    }

    if(!message) {
      setError('Enter a message');
      return;
    }   

    await upload(); 
  }

  return (
    <> 
      <Helmet>
        <title> Contact Us | PlanetryVilage LLC </title>
      </Helmet>
      
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} lg={6}>
          {/* <Box
            display={{ xs: "none", lg: "flex" }}
            // width="calc(100% - 2rem)"
            // height="calc(100vh - 2rem)" 
            ml={2}
            // mt={2}
            sx={{ backgroundImage: `url(${bgImage})`, borderRadius:2, width:"calc(100% - 2rem)", height:"calc(100vh - 2rem)"  }}
          /> */}

          <Box 
            display={{ xs: "none", lg: "flex" }}
            // width="calc(100% - 2rem)"
            // height="calc(100vh - 2rem)" 
            ml={2}
          // component="img" src={`/assets/images/company/tech.jpg`} 
          sx={{ backgroundRepeat: 'no-repeat', backgroundImage: `url(${`/assets/images/company/tech4.jpg`})`, borderRadius:2, width:"calc(100% - 2rem)", height:"calc(100vh - 0rem)"  }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4} 
        >
          <Box 
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}

            sx={{boxShadow: 3, mt:5, backgroundColor:"white", borderRadius:3, shadowBox:1, display:"flex", flexDirection:"column", justifyContent:"center" }}
          >
            <Box
              p={2}
              mx={2}
              mt={-3} 

              sx={{boxShadow: 3, borderRadius:2, background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)' }}
            >
              <Typography variant="h3" color="white">
                Contact us
              </Typography>
            </Box>
            <Box p={3}>

              <Typography variant="body2"  mb={1}>
                Located at 7455 Arroyo Crossing Spring Valley, Las Vegas, NV, USA 
              </Typography>

              <Typography variant="body2"  mb={3}>
                Phone: 0017026300634
              </Typography>

              <Typography variant="body2"  mb={3}> 
                For further questions, including partnership opportunities, please email
                planetry_vilage@aol.com or planetryvilagetech@gmail.com or contact using our contact form.
              </Typography>
              {/* <Box width="100%" component="form" method="post" autoComplete="off"> */}
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={fullname}
                      onChange = {(e) => onChangeFullname(e)}
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true,  }} 
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> 
                    <TextField
                      value={email}
                      onChange = {(e) => onChangeEmail(e)}
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}> 
                    <TextField
                      value={message}
                      onChange = {(e) => onChangeMessage(e)}
                      variant="standard"
                      label="What can we help you?"
                      placeholder="Describe your problem in at least 250 characters"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6} 
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <Typography variant='subtitle2' sx={{width:'100%', textAlign:'center' }} color='red'>{error}</Typography>

                  <Button type="button" variant="outlined" color="info" onClick={async () => {await sendMessage(); }} >
                    Send Message
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default ContactUs;
