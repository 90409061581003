
// import {useState} from 'react';
import { useNavigate,  } from 'react-router-dom'; 
import PropTypes from 'prop-types';

import { Card, Typography, Stack, 
         } from '@mui/material';
import { LoadingButton } from '@mui/lab';
      
  

// ----------------------------------------------------------------------------
 

export default function Home ({packItem}) { 
  const navigate = useNavigate();   
  const {id, company, otherType, ready, price} = packItem;
    
 
  return ( <Stack direction='column' textAlign='center' >  
    <Card sx={{ position: 'absolute', zIndex:1, maxWidth: 200, mx:{xs:12, sm:12, md:18},   p:{xs:1, sm:1, md:2}, borderRadius:1,  color:'white', background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)' }}>
      <Typography sx={{fontSize:12,}}>{company}</Typography>
      <Typography sx={{fontSize:12, }}>Price</Typography>
    </Card>
    <Card sx={{ position: 'relative', maxWidth: 680, m:1, mt:4, p:1,  color:'white', background:'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,36,121,1) 42%, rgba(0,255,154,1) 76%)' }} > 
        
       <Typography sx={{mt:7}}>{otherType}</Typography>
       <Typography sx={{mb:2}}>starts at ${price}</Typography> 
       <Typography sx={{mb:5}}>ready in {ready}</Typography> 

       <LoadingButton  size="small" type="submit" variant="contained" onClick={() => navigate(`/packages/${id}`) }>   
         <Typography>Read more</Typography>
      </LoadingButton>
    </Card>  

    </Stack>
  );
}



Home.propTypes = {
  packItem: PropTypes.object, 
};