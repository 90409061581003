 
import { Helmet } from 'react-helmet-async'; 
import { useNavigate } from "react-router-dom";  

// mui
import { Container, Grid, Typography, Box, Card, Button  } from '@mui/material';  
 

// sections 
import {  
   Home  
} from '../../sections/@users/homeUser';


const packs = [
  {id:1,
    company: 'Small business', 
    otherType: 'Website Development',
    ready: '1 month',
    price: 900
  },
  {id:2,
    company: 'Medium Business', 
    otherType: 'Website Development',
    ready: '2-4 months',
    price: 2000
  },  
  {id:3,
    company: 'Large Business', 
    otherType: 'Website Development',
    ready: '3-6 months',
    price: 5000
  }, 



  {id:4,
    company: 'Simple app', 
    otherType: 'Web App Development',
    ready: '2 - 3 months',
    price: 6000
  },
  {id:5,
    company: 'Less complex app', 
    otherType: 'Web App Development',
    ready: '3 - 4 months',
    price: 30000
  },  
  {id:6,
    company: 'Complex app', 
    otherType: 'Web App Development',
    ready: '4 - 6 months',
    price: 60000
  },  



  /* {id:7,
    company: 'Simple app', 
    otherType: 'Mobile App Development',
    ready: '2 - 3 months',
    price: 7000
  },
  {id:8,
    company: 'Less complex app', 
    otherType: 'Mobile App Development',
    ready: '3 - 4 months',
    price: 40000
  },  
  {id:9,
    company: 'Complex app', 
    otherType: 'Mobile App Development',
    ready: '4 - 6 months',
    price: 70000
  },  */
]


const technology = [
  {
    id:1,
    description: 'We are now working on cutting edge projects to take the IT industry to the next level',
    image: 'tech.jpg'
  },
  {
    id:2,
    description: 'Our goal is to change the future of Technology by improving the existing technologies',
    image: 'tech7.jpg'
  },
  {
    id:3,
    description: 'We are about to enhance the healthcare industry with new devices that will be so helpful',
    image: 'tech8.jpg'
  },
  {
    id:4,
    description: 'Our technology makes the tasks execution so efficient and eases the documentation',
    image: 'tech4.jpg'
  },
  {
    id:5,
    description: 'Our AI enabled technology implementation allows you to go where you have never been before',
    image: 'tech5.jpg'
  },
  {
    id:6,
    description: 'Our vision is to be one of the world leading company in the robotics industry',
    image: 'tech6.jpg'
  },
]

 
export default function HomePage() {  
  const navigation = useNavigate()
  
  return (
    <>
      <Helmet>
        <title> Home | PlanetryVilage LLC  </title>
      </Helmet>
      
      
      <Container maxWidth="xl"  sx={{px:{xs:0, sm:0, md:0}, }} > 
        
          <Grid container spacing={1} sx={{pb:1, }} > 
            <Grid  item xs={12} sm={12} md={12} lg={12} >
              <Grid container spacing={0}  alignItems="center"  sx={{borderRadius:1, px:{xs:2, sm:2, md:20}, py:5, background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)', backgroundImage: `url('/assets/images/company/tech3.jpg')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',  }}>  
                 
                
                <Grid  item xs={12} sm={12} md={6} >
                  <Typography sx={{color:'lightblue', fontSize:18, }}>Welcome to PlanetryVilage LLC</Typography>
                  
                  <Card sx={{mt:2, p:2, backgroundColor:{md:'transparent', sm:'white', xs:'white'}, boxShadow:3 }}>
                    <Typography sx={{fontWeight:700, color:{md:'lightblue', sm:'green', xs:'green'}, mt:3, fontSize:{md:25, sm:20, xs:20} }}>Our core expertise includes, but is not limited to: </Typography>
                    <Typography sx={{color:{md:'white', sm:'black', xs:'black'},  }}>Social media apps like Facebook, Instagram </Typography>
                    <Typography sx={{color:{md:'white', sm:'black', xs:'black'},  }}>Video conferencing apps like Zoom, Microsoft Teams </Typography>
                    <Typography sx={{color:{md:'white', sm:'black', xs:'black'},  }}>Live streaming app </Typography>
                    <Typography sx={{color:{md:'white', sm:'black', xs:'black'},  }}>Multistreaming apps like Streamyard, Restream </Typography>
                    <Typography sx={{color:{md:'white', sm:'black', xs:'black'},  }}>TV, and Radio app like Sky News TV </Typography>
                    <Typography sx={{color:{md:'white', sm:'black', xs:'black'},  }}>Music Streaming app like Spotify, Apple Music </Typography>
                  </Card>
  
                  
                  {/* <Typography sx={{color:'white', mt:3}}>Our company has developed a large number of apps ranging from social media apps to multistreaming apps</Typography>
                  
                  <Button type="button" variant="contained" color="info" onClick={async () => {navigation('/contact'); }} >
                    Contact Us
                  </Button> */}
                </Grid>

                <Grid  item xs={12} sm={12} md={6}>
                  <Typography sx={{color:'white', mt:3}}>Our company has developed a large number of apps ranging from social media apps to multistreaming apps</Typography>
                  
                  <Button type="button" variant="contained" color="info" onClick={async () => {navigation('/contact'); }} >
                    Contact Us
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid  item xs={12} sm={12} md={12} lg={12}> 
              <Grid container spacing={0}  alignItems="center" justifyContent="center">  
 
                { packs.map((item, key) => (
                <Grid key={key} item xs={12} sm={12} md={4}> 
                  <Home  packItem={item} />
                </Grid> 
                ))} 

              </Grid>
            </Grid>  

            <Grid  item xs={12} sm={12} md={12} lg={12} >
              <Grid container spacing={0}  alignItems="center" justifyContent="center"  sx={{borderRadius:1, px:{xs:2, sm:2, md:5}, py:5, background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,36,121,1) 42%, rgba(0,255,154,1) 76%)' }}>
                <Grid  item xs={12} sm={12} md={6} >
                  <Box component="img" sx={{  borderRadius:1, p:1 }} src='/assets/images/company/tech2.jpg'/>
                </Grid>
                <Grid  item xs={12} sm={12} md={6} >
                  <Typography sx={{color:'white',  }}>Our successful projects have been the development of social media platforms.  We have dedicated all our expertise to develop an app with such a magnitude </Typography>
                  <Typography sx={{color:'white',  }}>The projects have been completed. </Typography>
                </Grid>
              </Grid>
            </Grid>


            <Grid  item xs={12} sm={12} md={12} lg={12} >
              <Grid container spacing={0}  alignItems="center" justifyContent="center"  sx={{borderRadius:1, background: 'linear-gradient(90deg, #40E0D0 0%, #FF8C00 62%, #40E0D0 96%)' }}>
                { technology.map((item, key) => (
                <Grid key={key}  item xs={12} sm={12} md={3} >
                  <Card sx={{m:1, borderRadius:1}}>
                    <Typography sx={{p:1}}>{item.description}</Typography>
                    <Box component="img" src={`/assets/images/company/${item.image}`} sx={{height:200, width:'100%'}}/>
                  </Card>
                </Grid> 
                ))}
              </Grid>
            </Grid>


          </Grid> 
 
          
      </Container>  
    </>
  );
}



